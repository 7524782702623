<template>
<div id="swagger-ui">
  <openapi-explorer :spec-url="openapi">
    <div slot="overview">
      <h1>GDS API</h1>
    </div>
  </openapi-explorer>
</div>
</template>

<script>
import env from "@/configs/env.json";

// import('swagger-ui/dist/swagger-ui.css');
let host = '';
let docsEndpoint = '';
if(location.hostname.includes('web')) {
  host = location.hostname.replace('web.', '');
  docsEndpoint = 'https://' + host + '/docs/api-docs.json'
} else {
  docsEndpoint = env.endpointDocs
}
const token = localStorage.getItem('token').slice(7);
import 'openapi-explorer';
import {mapGetters} from "vuex";
export default {
  name: "Swagger",
  data() {
    return {
      jsonApi: null,
      openapi: docsEndpoint + '?token='+token
    }
  },
  computed: {
    ...mapGetters('auth', ['user'])
  },
  mounted() {
    if(!this.user.roles.includes('developer') && !this.user.roles.includes('superadmin')){
        this.$router.push({name: 'Home'})
    }
  }
}
</script>

<style scoped>

</style>